import React, { useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import useFetchApiData from "../hooks/useFetchApiData"; // Import the custom hook
import Loader from "../Components/Loader";
import "./Tables.css";
import ExportModal from "../Components/ExportModal";

const TotalApiHits = () => {
  const { apiData, loading, error } = useFetchApiData(); // Fetch API data

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [dateFilter, setDateFilter] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleDateChange = (e) => {
    setDateFilter(e.target.value);
  };

  const [columnFilters, setColumnFilters] = useState({
    query: "",
    response: "",
    date: "",
    organization: "",
    cacheHit: "",
  });

  const [activeFilters, setActiveFilters] = useState({
    query: false,
    response: false,
    date: false,
    organization: false,
    cacheHit: false,
  });

  const handleColumnFilterChange = (e, column) => {
    setColumnFilters({
      ...columnFilters,
      [column]: e.target.value,
    });
    setCurrentPage(1);
  };

  const toggleFilter = (column) => {
    setActiveFilters({
      ...activeFilters,
      [column]: !activeFilters[column],
    });
  };

  const filteredData = apiData.filter((row) => {
    const matchesGlobalSearch =
      row.query?.toLowerCase().includes(globalSearch.toLowerCase()) ||
      row.response?.toLowerCase().includes(globalSearch.toLowerCase()) ||
      row.organization?.toLowerCase().includes(globalSearch.toLowerCase()) ||
      row.cacheHit?.toLowerCase().includes(globalSearch.toLowerCase()) ||
      row.date?.toLowerCase().includes(globalSearch.toLowerCase()); // Include date in the global search

    const matchesColumnFilters =
      row.query?.toLowerCase().includes(columnFilters.query.toLowerCase()) &&
      row.response
        ?.toLowerCase()
        .includes(columnFilters.response.toLowerCase()) &&
      row.organization
        ?.toLowerCase()
        .includes(columnFilters.organization.toLowerCase()) &&
      row.cacheHit
        ?.toLowerCase()
        .includes(columnFilters.cacheHit.toLowerCase()) &&
      row.date?.toLowerCase().includes(columnFilters.date.toLowerCase());

    const rowDateObj = new Date(row.date);
    const rowDateFormatted = rowDateObj.toISOString().split("T")[0]; // "YYYY-MM-DD"
    const matchesDate = dateFilter === "" || rowDateFormatted === dateFilter;
    console.log(
      `Row Date: ${rowDateFormatted}, Selected Date: ${dateFilter}, Match: ${matchesDate}`
    );

    return matchesGlobalSearch && matchesColumnFilters && matchesDate;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentData = filteredData
    .reverse()
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleHover = (e, content) => {
    setTooltipContent(content);
    setTooltipPosition({ x: e.clientX, y: e.clientY });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const renderPageNumbers = () => {
    const maxPageDisplay = 5; // Number of page buttons to display at once
    let startPage = Math.max(1, currentPage - Math.floor(maxPageDisplay / 2));
    let endPage = Math.min(totalPages, startPage + maxPageDisplay - 1);

    if (endPage - startPage < maxPageDisplay - 1) {
      startPage = Math.max(1, endPage - maxPageDisplay + 1);
    }

    const pageButtons = [];
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`px-3 py-1 border rounded mx-1 ${
            currentPage === i ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-[#3d74a4] font-bold mb-4 text-xl">Total API Hits</h1>

      {loading ? (
        <p>
          <Loader />
        </p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <div className="flex justify-between mb-6 flex-wrap">
            <div className="relative flex items-center gap-[10px] border border-[#92A7BE] rounded-[251px] h-[59px] p-[20px] mr-4 bg-[#fff] flex-1">
              <FaSearch className="text-[#92A7BE]" />
              <input
                type="text"
                placeholder="Search..."
                className="w-full bg-transparent focus:outline-none"
                value={globalSearch}
                onChange={(e) => setGlobalSearch(e.target.value)}
              />
            </div>
            <div>
              <button
                className="px-[30px] py-[17.5px] h-[59px] gap-[24px] rounded-full border border-[#92A7BE] bg-[#3d7bb5] text-white mr-2"
                onClick={openModal}
              >
                Export
              </button>
            </div>
            <div>
              <input
                type="date"
                className="flex items-center px-[30px] py-[17.5px] w-[231px] h-[59px] gap-[24px] rounded-full border border-[#92A7BE] text-gray-600"
                value={dateFilter}
                onChange={handleDateChange}
                aria-placeholder="Today"
              />
            </div>
          </div>

          <div className="overflow-auto rounded-2xl max-sm:h-[600px] h-[640px]">
            <table className="min-w-full bg-white border border-gray-300 truncate ">
              <thead>
                <tr className="bg-[#f6fbfd]">
                  <th className="px-4 py-2 border text-left">
                    <div className="flex items-center justify-between">
                      {activeFilters.query ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-40 p-1  focus:outline-none bg-transparent"
                            value={columnFilters.query}
                            onChange={(e) =>
                              handleColumnFilterChange(e, "query")
                            }
                            placeholder="Search"
                            autoFocus
                          />
                          <FaTimes
                            className="cursor-pointer ml-2 text-red-500"
                            onClick={() => toggleFilter("query")}
                          />
                        </div>
                      ) : (
                        <>
                          <span>Query</span>
                          <FaSearch
                            className="cursor-pointer ml-2"
                            onClick={() => toggleFilter("query")}
                          />
                        </>
                      )}
                    </div>
                  </th>
                  <th className="px-4 py-2 border text-left w-[500px]">
                    <div className="flex items-center justify-between">
                      {activeFilters.response ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-40 p-1 bg-transparent focus:outline-none"
                            value={columnFilters.response}
                            onChange={(e) =>
                              handleColumnFilterChange(e, "response")
                            }
                            placeholder="Search"
                            autoFocus
                          />
                          <FaTimes
                            className="cursor-pointer ml-2 text-red-500"
                            onClick={() => toggleFilter("response")}
                          />
                        </div>
                      ) : (
                        <>
                          <span>Response</span>
                          <FaSearch
                            className="cursor-pointer ml-2"
                            onClick={() => toggleFilter("response")}
                          />
                        </>
                      )}
                    </div>
                  </th>
                  <th className="px-4 py-2 border text-left">
                    <div className="flex items-center justify-between">
                      {activeFilters.organization ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-40 p-1 bg-transparent focus:outline-none"
                            value={columnFilters.organization}
                            onChange={(e) =>
                              handleColumnFilterChange(e, "organization")
                            }
                            placeholder="Search"
                            autoFocus
                          />
                          <FaTimes
                            className="cursor-pointer ml-2 text-red-500"
                            onClick={() => toggleFilter("organization")}
                          />
                        </div>
                      ) : (
                        <>
                          <span>Organization</span>
                          <FaSearch
                            className="cursor-pointer ml-2"
                            onClick={() => toggleFilter("organization")}
                          />
                        </>
                      )}
                    </div>
                  </th>
                  <th className="px-4 py-2 border text-left">
                    <div className="flex items-center justify-between">
                      {activeFilters.date ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-40 bg-transparent p-1  focus:outline-none"
                            value={columnFilters.date}
                            onChange={(e) =>
                              handleColumnFilterChange(e, "date")
                            }
                            placeholder="Search"
                            autoFocus
                          />
                          <FaTimes
                            className="cursor-pointer ml-2 text-red-500"
                            onClick={() => toggleFilter("date")}
                          />
                        </div>
                      ) : (
                        <>
                          <span>Date & Time</span>
                          <FaSearch
                            className="cursor-pointer ml-2"
                            onClick={() => toggleFilter("date")}
                          />
                        </>
                      )}
                    </div>
                  </th>
                  <th className="px-4 py-2 border text-left">
                    <div className="flex items-center justify-between">
                      {activeFilters.cacheHit ? (
                        <div className="flex items-center">
                          <input
                            type="text"
                            className="w-40 p-1 bg-transparent focus:outline-none"
                            value={columnFilters.cacheHit}
                            onChange={(e) =>
                              handleColumnFilterChange(e, "cacheHit")
                            }
                            placeholder="Search"
                            autoFocus
                          />
                          <FaTimes
                            className="cursor-pointer ml-2 text-red-500"
                            onClick={() => toggleFilter("cacheHit")}
                          />
                        </div>
                      ) : (
                        <>
                          <span className="whitespace-nowrap">Cache Hit</span>
                          <FaSearch
                            className="cursor-pointer ml-2"
                            onClick={() => toggleFilter("cacheHit")}
                          />
                        </>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentData.length > 0 ? (
                  currentData.map((row, index) => (
                    <tr key={index} className="text-[#626183]">
                      <td
                        className="px-4 py-2 border cursor-pointer"
                        onMouseMove={(e) => handleHover(e, row.query || "N/A")}
                        onMouseLeave={handleMouseLeave}
                      >
                        {" "}
                        <div className="whitespace-pre-line overflow-hidden scrollbar-webkit h-[45px] w-[400px]">
                          {row.query}
                        </div>
                      </td>
                      <td
                        className="px-4 py-2 border cursor-pointer"
                        onMouseMove={(e) =>
                          handleHover(e, row.response || "N/A")
                        }
                        onMouseLeave={handleMouseLeave}
                      >
                        <div className="whitespace-pre-line overflow-hidden scrollbar-webkit h-[45px]">
                          {row.response}{" "}
                        </div>
                      </td>
                      <td className="px-4 py-2 border">{row.organization}</td>
                      <td className="px-4 py-2 border">{row.date}</td>
                      <td className="px-4 py-2 border">{row.cacheHit}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-4">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex items-center justify-between">
            <span>Total Queries: {filteredData.length}</span>

            <div className="px-4">
              {/* First Page Button */}
              <button
                className="px-3 py-1 border rounded mr-2"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </button>

              {/* Previous Page Button */}
              <button
                className="px-3 py-1 border rounded mr-2"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {/* Page Numbers */}
              {renderPageNumbers()}

              {/* Next Page Button */}
              <button
                className="px-3 py-1 border rounded ml-2"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>

              {/* Last Page Button */}
              <button
                className="px-3 py-1 border rounded ml-2"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>
          </div>
          <ExportModal
            isOpen={isModalOpen}
            onClose={closeModal}
            data={apiData}
            currentPageData={currentData}
            fileName={`API_Hits_${dateFilter ? dateFilter : getTodayDate()}`}
          />
        </>
      )}
      {showTooltip && (
        <div
          className="fixed bg-gray-700 text-white text-sm rounded p-2"
          style={{
            top: tooltipPosition.y + 10,
            left: tooltipPosition.x + 10,
            zIndex: 1000,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default TotalApiHits;
